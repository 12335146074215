import React from "react"
import H2 from "../../core/H2"
import H3 from "../../core/H3"
// import { Doughnut } from "react-chartjs-2"
import P from "../../core/P"

// const data = {
//   labels: [
//     "Proptech & Real Estate",
//     "Fintech",
//     "Healthcare",
//     "On-Demand",
//     "Social Media ",
//     "ECommerce",
//     "Retail & Marketplace",
//     "Others",
//   ],
//   datasets: [
//     {
//       label: "Industry Focus",
//       backgroundColor: [
//         "#ff744d",
//         "#db264d",
//         "#f017ff",
//         "#335fff",
//         "#2c23e8",
//         "#1adaf8",
//         "#28b873",
//         "#ffd333",
//       ],
//       bgcolor: [
//         "#ff744d",
//         "#db264d",
//         "#f017ff",
//         "#335fff",
//         "#2c23e8",
//         "#1adaf8",
//         "#28b873",
//         "#ffd333",
//       ],
//       borderColor: "rgba(0,10,220,0)",
//       data: [20, 20, 10, 10, 13, 7, 10, 10],
//     },
//   ],
// }

const values = [
  {
    title: "Proptech & Real Estate",
    percentage: "20%",
    class: "5px solid #ff744d",
  },
  {
    title: "Fintech",
    percentage: "20%",
    class: "5px solid #db264d",
  },
  {
    title: "Healthcare",
    percentage: "10%",
    class: "5px solid #f017ff",
  },
  {
    title: "On-Demand",
    percentage: "10%",
    class: "5px solid #335fff",
  },
  {
    title: "Social Media ",
    percentage: "13%",
    class: "5px solid #2c23e8",
  },
  {
    title: "ECommerce",
    percentage: "7%",
    class: "5px solid #1adaf8",
  },
  {
    title: "Retail & Marketplace",
    percentage: "10%",
    class: "5px solid #28b873",
  },
  {
    title: "Others",
    percentage: "10%",
    class: "5px solid #ffd333",
  },
]

export const IndustryFocus = () => {
  return (
    <div className="bg-transperant">
      <div className="max-w-screen-xl mx-auto">
        <div data-aos="fade-up">
          <H2 title={`Industry Focus`} className="text-center" />
          <P
            title={`Every sector of industry is becoming digitalize rapidly and we are trying to make solution that addresses real business problem through our services.`}
            className="text-center mt-8 text-shark-400"
          />
        </div>
        <div className="flex flex-wrap ">
          <div className="lg:w-1/2 w-full lg:p-20 md:p-16 p-8 flex justify-center content-center flex-wrap">
            <div className="w-full" data-aos="fade-right">
              {/* <Doughnut
                id="chart-area"
                data={data}
                height={380}
                width={350}
                className="industry_chart"
                options={{
                  legend: {
                    display: false,
                  },
                  responsive: true,
                  plugins: {
                    deferred: {
                      enabled: true,
                      delay: 200,
                    },
                  },
                }}
              /> */}
              <img
                src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Artboard.webp"
                alt="Industry Focus"
                title="Industry Focus"
                loading="lazy"
                height="500"
                width="500"
              />
            </div>
          </div>
          <div className="lg:w-1/2 w-full p-4 flex justify-center content-center flex-wrap">
            <div className="flex flex-wrap" data-aos="fade-up">
              {values.map(e => (
                <div className={`w-1/2 py-4`} key={Math.random()}>
                  <div className="px-4" style={{ borderLeft: e.class }}>
                    <P title={e.title} noPad className="text-shark-400" />
                    <H3 title={e.percentage} className="pt-4 text-shark-500" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndustryFocus
